import request from '@/utils/request'

//推广列表
export function getSpreadList(query) {
    return request({
        url: '/admin/spread-list',
        method: 'get',
        params: query
    })
}
//添加推广商品
export function spreadAdd(data) {
    return request({
        url: '/admin/spread-add',
        method: 'post',
        data: data
    })
}
//编辑推广商品
export function spreadEdit(data) {
    return request({
        url: '/admin/spread-edit/' + data.id,
        method: 'put',
        data: data
    })
}
//批量删除
export function spreadDelete(data) {
    return request({
        url: '/admin/spread-delete',
        method: 'delete',
        data: data
    })
}
//修改状态
export function spreadStatus(data) {
    return request({
        url: '/admin/spread-status/' + data.id,
        method: 'post',
        data: data
    })
}

