<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div class="search-container-fn-input">
        <label>模板名称：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="请输入模板名称"
          prefix-icon="el-icon-search"
          clearable
          @change="getList('restPage')"
          v-model="searchParams.name"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;margin-right: 20px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                          background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('add', {})"
            >新增
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                          background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                          background-color: #2362FB; border-color: #2362FB"
            @click="handleAddDelEdit('del', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                            background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                            background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click="getInfo(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-if="'goods_status' == column.column_prop"
              class="switch"
              @change="changeStatus(scope.row)"
              v-model="scope.row.goods_status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              @click="under(scope.row.cover_pic, 'pic' + scope.$index)"
              v-if="scope.row.cover_pic"
            >
              <a style="color:#2362FB" @click="img">查看图片</a>
              <el-image
                :ref="'pic' + scope.$index"
                style="width: 0; height: 0;"
                :z-index="zIndex"
                :src="scope.row.cover_pic"
                :preview-src-list="scope.row.cover_pic"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'order_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.order_status)
                "
                v-model="searchParams.order_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'goods_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.goods_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.goods_name)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>

    <el-dialog
      :visible.sync="dialogTableVisible"
      width="30%"
      :show-close="false"
    >
      <div>
        <div style="margin-bottom:20px">
          <span style="font-size:22px;border-bottom: #2362FB 3px solid;"
            >设置商品佣金</span
          >
        </div>
      </div>
      <div style="display: flex;margin-bottom:15%;" v-if="type == 'add'">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>选择商品</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-select
            filterable
            clearable
            v-model="info.goods_id"
            placeholder="请选择"
            size="mini"
            @change="getGoodsInfo"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.goods_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;margin-bottom:15%;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>佣金</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-radio
            v-model="info.commission_type"
            :label="1"
            style="margin-bottom:15px;"
            >自定义佣金比例</el-radio
          >
          <el-radio v-model="info.commission_type" :label="2"
            >自定义佣金金额</el-radio
          >
          <el-input
            size="mini"
            v-model="info.commission"
            class="input-radio"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false;info={}" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="_ok" size="mini">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getGoodsSelect } from "@/newApi/commit";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import {
  getSpreadList,
  spreadAdd,
  spreadEdit,
  spreadDelete,
  spreadStatus,
} from "@/newApi/promotion.js";
import { Message } from "element-ui";
import {
  mapState
} from "vuex";

export default {
  name: "promotionList",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this._getGoodsSelect();
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  data() {
    return {
      table_type:"promotionList",
      operateList:[],
      dialogTableVisible: false,
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "goods_name",
          isSearch: false,
        },
        {
          name: "status",
          isSearch: false,
        },
        {
          name: "username",
          isSearch: false,
        },
        {
          name: "order_sn",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
      flag: false,
      zIndex: 999,
      type: "add",
      info: {},
      options: [],
    };
  },
  components: {
    Pagination,
    editTableHead,
  },

  methods: {
    changeStatus(e) {
      console.log(e);
      let data = {
        id: e.id,
        goods_status: e.goods_status
      }
      spreadStatus(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    getGoodsInfo(e) {
      console.log(e);
      this.options.forEach((item) => {
        if (e == item.id) {
          this.info.goods_name = item.goods_name;
          this.info.goods_id = item.id;
        }
      });
    },
    _spreadAdd(data) {
      spreadAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getList();
          this.dialogTableVisible = false;
          this.info = {};
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    _spreadEdit(data) {
      spreadEdit(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
          this.dialogTableVisible = false;
          this.info = {};
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    _ok() {
      console.log(2);
      if (this.type == "add") {
        this._spreadAdd(this.info);
      } else {
        let data = {
          id: this.info.id,
          commission: this.info.commission,
          commission_type: this.info.commission_type,
        };
        this._spreadEdit(data);
      }
    },
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    //删除
    _spreadDelete(data) {
      //提示框
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          spreadDelete(data).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增删除编辑
    handleAddDelEdit(type, row) {
      if (type == "add") {
        this.dialogTableVisible = true;
        this.type = "add";
      } else if (type == "edit") {
        console.log("编辑");
        this.dialogTableVisible = true;
        this.type = "edit";
        this.info = row;
      } else {
        console.log("删除");
        let data = {
          ids: [row.id],
        };
        this._spreadDelete(data);
      }
    },
    img() {
      this.flag = true;
    },
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
     _getAdminFieldIndex() {
        this.tableHead = this.table_options.promotionList
      },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;

      getSpreadList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
.input-radio {
  width: 150px;
  margin-top: 20px;
}
</style>
